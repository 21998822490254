import {LOAD_PRICE} from "../../constants/actionTypes";
import React from "react";
import agent from "../../agent";
import {connect} from "react-redux";
import {apiStore} from "../../store";
import CountUp from 'react-countup';
import {getCurrency} from "../../utils/price";
import {apiOrder} from "../../Stores/Order";

const mapStateToProps = state => ({
    ...state.todos,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload, actualSendData) =>
        dispatch({type: LOAD_PRICE, payload, actualSendData}),
});

class PriceText extends React.Component {

    state = {
        sendData: null,
        price: 0,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newSendData = JSON.stringify(this.props.sendData);

        if (newSendData !== prevState.sendData && newSendData !== this.props.actualSendData) {
            this.setState({sendData: newSendData});

            if (newSendData.webUUId !== '') {
                this.props.onLoad(agent.Price.get(newSendData), newSendData);
            }
        }

        const siteUUID = apiStore.getState().uuid;

        if (this.props.price && prevProps.price.uuid && siteUUID === '') {
            apiStore.getState().setUUID(this.props.price.uuid);
        }

        if (this.props.price && prevProps.price.insert && this.props.price.insert !== prevProps.price.insert) {
            apiOrder.getState().setInsert(this.props.price.insert);
        }

        if (this.props.price && prevProps.price.objId && this.props.price.objId !== prevProps.price.objId) {
            apiOrder.getState().setObjId(this.props.price.objId);
        }
    }

    _onChange() {
        const newPrice = apiStore.getState().getPrice();

        if (newPrice !== this.state.price) {
            this.setState({price: newPrice});
        }
    }

    render() {
        let newPrice = 0;

        if (this.props.price && this.props.price.prices && this.props.price.prices.wardrobe) {
            newPrice = this.props.price.prices.wardrobe.priceWithVat;
            apiStore.getState().setPrice(newPrice);
        }

        let currency = ' ';

        if (this.props.price && this.props.price.currencyCode) {
            currency += getCurrency(this.props.price.currencyCode)
        }

        if (newPrice !== 0 && this.props.showPrice) {
            return (
                <>
                    <CountUp
                        start={this.state.price}
                        end={newPrice}
                        duration={2.75}
                        separator=" "
                        decimals={2}
                        decimal=","
                        prefix=""
                        suffix={' ' + currency}
                        onEnd={() => this._onChange()}
                    />
                </>
            );
        } else {
            return <></>;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceText);