import {PROFILES_LANG} from './components/profiles';
import {INFO_LANG} from './components/info';
import {NAVIGATION_LANG} from './components/navigation';
import {INPUTS_LANG} from './components/inputs';
import {BUTTONS_LANG} from './components/buttons';
import {PRODUCTS_LANG} from './components/products';
import {ACCESSORIES_LANG} from './components/accessories';
import {TABS_LANG} from './components/tabs';

export const LANG = {
    "roomTypeDescription":
        "V prvom kroku vytvorenia Vašej vstavanej skrine si kliknutím na obrázok vyberte typ pôdorysu (typ A, B, C alebo D) vzhľadom na to, kde má byť Vaša vstavaná skriňa umiestnená.",
    "roomTitle": "Zadanie parametrov",
    "selectCorpusesSetSpacesCountTitle": "Počet priestorov",
    "selectCorpusesSetSpacesCountTitleDescription":
        "Vyberte si počet priestorov, ktoré bude mať Vaša vstavaná skriňa. Systém Vám automaticky prepočíta optimálny počet dvier na počet priestorov podľa Vami zadaných rozmerov, avšak máte možnosť si tento počet znížiť alebo zvýšiť. Optimálny rozmer priestorov je okolo 80 cm. Priestor nad 100 cm sa rozdelí na polovicu z dôvodu možnosti prehnutia políc. Po vyplnení všetkých údajov pokračujte ďalej kliknutím na tlačidlo Pokračovať",

    "BasicSettingsTitle": "Základné nastavenia",
    "selectDoorsSetSpacesCountTitle": "Počet priestorov",
    "selectCorpusesTitle": "Skladanie vnútra",
    "selectCorpusesModalTitle": "Výber korpusu",
    "selectCorpusesDescription": "Skladanie vnútra",
    "selectCorpusesVariantsTitle": "Výber z preddefinovaných korpusov",
    "SelectCorpusTooltipTitle": "Kliknutim sa zobrazí",
    "SelectCorpusTooltipText": " zoznam pre vyber korpusu do daneho priestoru",
    "selectDoorsVariantsTitle": "Výber z preddefinovaných kombinácií dverí",
    "selectDoorSpacesCountTitle": "Výber typov dvier",
    "selectDoorsTitle": "Výber typu dvier",
    "selectDoorsDescription": "V tomto kroku si vyberte pre každý priestor typ posuvných dvier. Na výber sú plné dvere, ale aj dvere delené (na polovicu, na tretiny, dokonca aj na menšie časti). Priestor pre ktorý sa aktuálne definuje typ je označený zelenou farbou.",
    "roomTypeTitle": "Výber typu pôdorysu",
    "Attributes": "Parametre",
    "Corpuses": "Vnútro",
    "Doors": "Dvere",
    "Order": "Objednať",
    "SubmitOrder": "Objednať",
    "Next": "Ďalej",
    "Preview": "Naspäť",
    "NextStep": "Pokračovať",
    "PreviewStep": "Naspäť",
    "SaveOrder": "Uložiť",
    "Close": "Zatvoriť",
    "Type": "Typ",
    "TypeA": "Typ A",
    "TypeB": "Typ B",
    "TypeC": "Typ C",
    "TypeD": "Typ D",
    "LongHanging": 'Dlhé vešanie',
    "ShortHanging": 'Krátke vešanie',
    "CombHanging": 'Kombinované vešanie',
    "Shoes": 'Obuv',
    "Shelf": 'Police',
    "Drawers": 'Zásuvky',
    "Glasses": 'Sklá',
    "Mirrors": 'Zrkadlá',
    "profiles": PROFILES_LANG,
    "info": INFO_LANG,
    "navigation": NAVIGATION_LANG,
    "inputs": INPUTS_LANG,
    "buttons": BUTTONS_LANG,
    "products": PRODUCTS_LANG,
    "accessories": ACCESSORIES_LANG,
    "tabs": TABS_LANG,
    "egeer_standard": 'Egeer standard',
    "egeer_exclusive": 'Egeer exclusive',
    "HideDoor": 'Dvere nezobrazené',
    "ShowDoor": 'Dvere zobrazené',
    "Width": 'Šírka',
    "Height": 'Výška',
    "Depth": 'Hĺbka',
    "LeftHeight": 'Ľavá výška stropu',
    "RightHeight": 'Pravá výška stropu',
    "LeftBackHeight": 'Ľavá zadná výška stropu',
    "LeftFrontHeight": 'Ľavá predná výška stropu',
    "RightBackHeight": 'Pravá zadná výška stropu',
    "RightFrontHeight": 'Pravá predná výška stropu',
    "HideDimensions": 'Kóty nezobrazené',
    "ShowDimensions": 'Kóty zobrazené',
    "SiteTitle": 'Konfigurátor pre vstavané skrine',
    "SelectTypeInfo": 'Konfigurátor pre vstavané skrine',
    "TypeVDName": 'Vstavaná skriňa\nkomplet',
    "TypeDName": 'Predný posuvný systém\nsamostatne',
    "TypeVName": 'Vnútro vstavanej skrine\nsamostatne',
    "BackOnHomeSite": 'Späť na hlavnú stránku',
    "SelectSpacesCountTitle": 'Výber počtu priestorov',
    "SelectDoorsCountTitle": 'Výber počtu dvier',
    "CorpusesVariantsTabName": 'Preddefinované',
    "ButtonShowCorpusVariantsModal": 'Zobraziť zoznam preddefinovaných variácií',
    "SelectVariantsDoorsTabName": 'Preddefinované',
    "SelectOwnDoorsTabName": 'Vlastné',
    "SelectCorpusesTitle": 'Skladanie vnútra',
    "SelectDTDTitle": 'Výber drevodekoru',
    "DTDMainTabName": 'Vnútro',
    "DTDSecondTabName": 'Bočnice',
    "SelectDoorSpacesCountTitle": 'Výber typu dvier',
    "SelectProfilesTitle": "Výber typu a farby profilu",
    "SelectDoorsFillTitle": "Výber výplní dvier",
    "DoorFillsAreEmptyError": "{0}. dvere nemajú zadanú {1} výplň",
    "DoorFillsAreEmptyErrorAnd": "{0} a {1}",
    "RoomTypeTitle": "Výber typu pôdorysu",
    "DivideOn": 'Rozdeliť priestor',
    "DivideOff": 'Zlúčiť priestor',
    "OrderForm": "Objednávkový formulár",
    "FirstName": "Meno",
    "LastName": "Priezvisko",
    "Description": "Poznámka",
    "Phone": "Telefón",
    "Email": "Email",
    "Street": "Ulica",
    "City": "Mesto",
    "ZIP": "PSČ",
    "BillAddress": "Fakturačná adresa",
    "DeliveryAddress": "Dodacia adresa",
    "isOrgOrder": "Nakupujem na firmu",
    "Organization": "Firma",
    "ICO": "IČO",
    "DIC": "DIČ",
    "IC DPH": "IČ DPH",
    "TermsAndConditions": "Prečítal som si a súhlasím s obchodnými podmienkami",
    "OrderInfo": "Pre dokončenie objednávky a záväzné objednanie, prosím kliknite na tlačidlo Objednať.",
    "SavePhoneInfo": "V prípade, že si želáte aby Vás kontaktoval náš odborný konzultant, zanechajte nám na Vás telefónny kontakt",
    "SaveEmailInfo": "Ak si želáte uložiť rozpočet, zadajte prosím emailovú adresu",
    "SelectCorpuse": "Vybrať korpus",
    "ChangeCorpuse": "Zmeniť korpus",
    "CorpusesSpaceIsEmptyError": "{0}. vnútro je prázdne",
    "AllSpacesMustHaveCorpusesInfo": "Pre pokračovanie musia mať všetky priestory vybraté vnútro.",
    "DivideSpaceOn": "Rozdeliť priestor",
    "DivideSpaceOff": "Zlúčiť priestor",
    "DivideButtonTooltipTitle": 'Kliknutím rozdelíte priestor',
    "DivideButtonTooltipText": 'na dva menšie celky pre ktorý vyberiete korpus',
    "ChangeDoorType": "Zmeniť typ",
    "SelectDoorType": "Vybrať typ",
    "Icon3DRotationTooltipTitle": "Rotácia nahľadu",
    "Icon3DRotationText": "Pridržaním ľavého tlačidla myši môžete meniť uhol pohľadu",
    "EditInputLabelwidth": 'Šírka',
    "EditInputLabelheight": 'Výška',
    "EditInputLabeldepth": 'Hĺbka',
    "BackDoor": 'Predošlé',
    "NextDoor": 'Ďalšie',
    "EditDoorSpacesHeight": "Upraviť rozmery výplňí",
    "BadMinValueFillHeight": 'Minimálna výška výplne je 10cm',
    "ThisHeightIsAutomaticCalculated": 'Výška je vypočitavaná na základe zadaných parametrov',
    "TotalHeightEditError": 'Zadaná výška nesmie presiahnuť celkovú výšku dvier',
    "ActivateFullScreen": 'Zväčšiť na celú obrazovku',
    'BadHeightValuesInfo': 'Rozdiel výšok nesmie byť väčší ako {0} cm, zadajte najmenší nameraný rozmer.',
    "AttributeTitleDTDMain": "Drevodekor",
    "AttributeTitleABSMain": "ABS",
    "AttributeTitleDTDSecondA": "Drevodekor dorazovej lišty",
    "AttributeTitleABSSecondA": "ABS dorazovej lišty",
    "AttributeTitleDTDSecondB": "Drevodekor dorazovej lišty a bočnice",
    "AttributeTitleABSSecondB": "ABS páska dorazovej lišty a bočnice",
    "AttributeTitleDTDSecondC": "Drevodekor bočnice a dorazovej lišty",
    "AttributeTitleABSSecondC": "ABS páska bočnice a dorazovej lišty",
    "AttributeTitleDTDSecondD": "Drevodekor bočnice",
    "AttributeTitleABSSecondD": "ABS páska bočníc",
    "Profile": "Profil",
    "white": "Biely",
    "sampan": "Šampaň",
    "silver": "Strieborný",
    "gold": "Zlatý",
    "silverMinimax": "Strieborný Minimax",
    "AllAttributesMustHaveBeenCorrectInfo": "Pre pokračovanie musia byť všetky zadané rozmery správne.",
    "WallColorBtnLabel": "Farba stien",
    "SpaceWidthDoorInfo": "Šírka jedného priestoru: {0} cm",
    "Visible": "Viditeľné",
    "Dimensions": 'Kóty',
    "SearchDTD": 'Vyhľadať drevodekor',
    "SearchDoorFill": 'Vyhľadať výplň',
    "SpacesCountIsEmptyErrorV": 'Pre pokračovanie musíte vybrať počet priestorov',
    "SpacesCountIsEmptyError": 'Pre pokračovanie musíte vybrať počet dvier',
    "Accessories": 'Doplnky',
    "WardrobePriceWithoutAccessories": 'Cena vstavanej skrine bez doplnkov',
    "PriceCalculation": 'Cenová kalkulácia',
    "CalculationItemNameVD": 'Vstavaná skriňa',
    "CalculationItemNameD": 'Predný posuvný systém',
    "CalculationItemNameV": 'Vnútro vstavanej skrine',
    "Delivery": 'Doprava',
    "Packing": 'Balné',
    "TotalPrice": 'Cena celkom',
    "Skip": 'Preskočiť',
    "Save": 'Uložiť',
    "selectDTDInTitle": 'Výber farby vnútra',
    "selectDTDOutTitle": 'Výber farby pre viditeľné časti',
    "selectDTDIn": 'Výber farby vnútra',
    "selectDTDOut": 'Výber farby pre viditeľné časti',
    "TotalPriceWithVat": 'Cena celkom s DPH ({0}%)',
    "OrderWasSuccessfullyCreated": 'Objednávka bola úspešne vytvorená',
    "OrderWasSuccessfullySaved": 'Váš rozpočet bol úspešne uložený',
    "OrderWasNotSuccessfullySaved": 'Váš rozpočet sa nepodarilo uložiť',
};
