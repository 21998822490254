import React, {useCallback} from "react";
import {useStore} from "../../../store";
import {connect} from 'react-redux'
import {Lang} from "../../../Utils";
import {makeStyles} from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";
import {Badge, Card, Grid, Paper} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import Tabs from "@material-ui/core/Tabs";
import Tab from '@material-ui/core/Tab';
import SiteAppBar from "./SiteAppBar";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import {controlSearchText} from "../../../utils/control";
import {isMobile} from 'react-device-detect';
import parse from 'html-react-parser';

const mapStateToProps = state => ({
    ...state.todos
});

const mapDispatchToProps = dispatch => ({});

const useCardStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 200,
        margin: 10,
    },
    media: {
        width: 200,
    },
    badge: {
        top: '85%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}));

const useListStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        margin: 0,
    },
    gridList: {
        overflow: 'auto',
    },
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function FillImgCard({type, woodDecor, productImages}) {
    const classes = useCardStyles();
    const selectedDTDType = useStore(state => state.selectedDTDType);
    const mainDTDId = useStore(state => state.mainDTDId);
    const secondDTDId = useStore(state => state.secondDTDId);
    const setDoorFill = useStore(state => state.setDoorFill);
    const selectNextDoorFill = useStore(state => state.selectNextDoorFill);
    const setPriceVersion = useStore(state => state.setPriceVersion);
    const selectedId = selectedDTDType === 'main' ? mainDTDId : secondDTDId;

    const onClickSelectFill = useCallback(
        (e) => {
            e.stopPropagation(); // stop it at the first intersection
            setDoorFill(type, woodDecor.id, productImages[woodDecor.id]);
            setPriceVersion();
            selectNextDoorFill();
        },
        [setDoorFill, type, woodDecor, productImages, setPriceVersion, selectNextDoorFill]
    );

    return (
        <GridListTile key={'GridListTile' + woodDecor.id}>
            <Badge
                invisible={selectedId !== woodDecor.id}
                color="primary"
                overlap="circle"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={<CheckIcon fontSize="small"/>}
                classes={{badge: classes.badge}}
            >
                <Card
                    className={classes.root}
                    key={woodDecor.id}
                    onPointerDown={(e) => onClickSelectFill(e)}
                >
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            className={classes.media}
                            alt={Lang(woodDecor.name, 'products')}
                            height="140"
                            image={process.env.PUBLIC_URL + '/' + woodDecor.img_road}
                            title={Lang(woodDecor.name, 'products')}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {woodDecor.code}
                            </Typography>
                            <Typography gutterBottom>
                                {Lang(woodDecor.name, 'products')}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Badge>
        </GridListTile>
    );
}

export const SMenu = (list, productImages, type) =>
    list.map(woodDecor => {
        return <FillImgCard woodDecor={woodDecor} productImages={productImages} type={type}/>
    });

function SelectDTDTab({menu}) {
    const classes = useListStyles();

    return (
        <div className={classes.root}>
            <GridList className={classes.gridList} cols={2.5}>
                {menu}
            </GridList>
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderWidth: 2,
        padding: '0',
    },
    content: {
        padding: 10,
    },
    tabs: {
        backgroundColor: theme.palette.background.paper,
    },
    tab: {
    },
    tabMobile: {
        fontSize: 8,
    },
    tabSelected: {
    },
    tabSelectedMobile: {
        fontSize: 8,
    },
    tabsBackground: {
        backgroundColor: theme.palette.grey[100],
    },
    divider: {
        height: 28,
        margin: 4,
        marginRight: 10,
    },
    mainContent: {
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

/**
 * @param {Number} count
 * @param {String} searchText
 * @param {Object} tabsContent
 * @param {Object} onClickSteps
 * @param {Function} onChangeSearchText
 * @returns {JSX.Element}
 * @constructor
 */
function SimpleTabs({
                        count,
                        searchText,
                        tabsContent,
                        onClickSteps,
                        onChangeSearchText
                    }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeSearch = (event, newValue) => {
        onChangeSearchText(newValue);
    };

    let tabClass = classes.tab;
    let tabSelectedClass = classes.tabSelected;

    if (isMobile) {
        tabClass =  classes.tabMobile;
        tabSelectedClass =  classes.tabSelectedMobile;
    }

    return (
        <>
            <SiteAppBar title={Lang('SelectDoorsFillTitle')} onClickSteps={onClickSteps}>
                <Paper square>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item sm={9}>
                            <Tabs
                                textColor="primary"
                                className={classes.tabs}
                                value={value}
                                onChange={handleChange}
                                aria-label="simple tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {tabsContent.map((tab, index) => (
                                    <Tab label={tab.menuItem} {...a11yProps(index)}
                                         classes={{
                                             root: tabClass, // class name, e.g. `root-x`
                                             selected: tabSelectedClass, // class name, e.g. `disabled-x`
                                         }}/>
                                ))}
                            </Tabs>
                        </Grid>
                        <Grid item sm={3}>
                            <InputBase
                                className={classes.input}
                                placeholder={Lang('SearchDoorFill')}
                                inputProps={{'aria-label': Lang('SearchDoorFill')}}
                                startAdornment={
                                    <>
                                        <Divider className={classes.divider} orientation="vertical"/>
                                        <SearchIcon color="primary"/>
                                    </>
                                }
                                onChange={e => handleChangeSearch(e, e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </SiteAppBar>
            <div className={classes.content}>
                {tabsContent.map((tab, index) => (
                    <TabPanel value={value} index={index}>
                        <div key={index + 'v' + count + '-' + btoa(searchText)} className={classes.mainContent}>
                            {tab.render}
                        </div>
                    </TabPanel>
                ))}
            </div>
        </>
    );
}

/**
 * @param {Object} section
 * @param {String} typeName
 * @param {Object} productImages
 * @param {String} searchText
 * @returns {{count: number, menuItem, render: JSX.Element}|boolean}
 */
function getDoorFillsTypeProducts(section, typeName, productImages, searchText) {
    const woodDecors = [];
    let count = 0;

    if (section.types[typeName] && Object.values(section.types[typeName].product).length) {
        section.types[typeName].product.map(product => {
            if (controlSearchText(product.code + ' ' + Lang(product.name, 'products'), searchText)) {
                woodDecors.push(product);
                count++;
            }

            return true;
        })

        const menu = SMenu(woodDecors, productImages, 'dtd');

        let langKey = section.name + ' ' + typeName;

        if (isMobile) langKey += 'Mobile';

        return {
            menuItem: parse(Lang(langKey, 'tabs')),
            render: <SelectDTDTab menu={menu}/>,
            count: count,
        };
    }

    return false;
}

class SelectFill extends React.Component {

    state = {
        count: 0,
        searchText: null,
        tabsContent: [],
    }

    componentDidMount() {
        this.actualizeTabContents(this.state.searchText);
    }

    /**
     * @param {String} searchText
     */
    actualizeTabContents(searchText) {
        let count = 0;
        const self = this;
        const tabsContent = [];
        const productImages = self.props.productImages.large;
        const doorsFillsDTDProducts = this.props.doorsFillsProducts.dtd;

        Object.keys(doorsFillsDTDProducts).forEach(function (sectionId) {
            const section = doorsFillsDTDProducts[sectionId];

            Object.keys(section.types).forEach(function (typeName) {
                if (typeName !== 'Exclusive') {
                    const doorFillsTypeProducts = getDoorFillsTypeProducts(section, typeName, productImages, searchText);

                    if (doorFillsTypeProducts) {
                        tabsContent.push(doorFillsTypeProducts)

                        count += doorFillsTypeProducts.count;
                    }
                }
            })
        });

        if (this.props.doorsFillsProducts.mirror) {
            const mirrorDecors = [];

            if (Object.values(this.props.doorsFillsProducts.mirror).length) {

                this.props.doorsFillsProducts.mirror.map(product => {
                    if (controlSearchText(product.code + ' ' + Lang(product.name, 'products'), searchText)) {
                        mirrorDecors.push(product);
                        count++;
                    }

                    return true;
                })

                const menu = SMenu(mirrorDecors, productImages, 'mirror');

                let langKey = 'Mirrors';

                if (isMobile) langKey += 'Mobile';

                tabsContent.push({
                    menuItem: parse(Lang(langKey, 'tabs')),
                    render: <SelectDTDTab menu={menu}/>,
                });
            }
        }

        Object.keys(doorsFillsDTDProducts).forEach(function (sectionId) {
            const section = doorsFillsDTDProducts[sectionId];

            Object.keys(section.types).forEach(function (typeName) {
                if (typeName === 'Exclusive') {
                    const doorFillsTypeProducts = getDoorFillsTypeProducts(section, typeName, productImages, searchText);

                    if (doorFillsTypeProducts) {
                        tabsContent.push(doorFillsTypeProducts)

                        count += doorFillsTypeProducts.count;
                    }
                }
            })
        });

        if (this.props.doorsFillsProducts.glass) {
            const glassDecors = [];

            if (Object.values(this.props.doorsFillsProducts.glass).length) {
                this.props.doorsFillsProducts.glass.map(product => {
                    if (controlSearchText(product.code + ' ' + Lang(product.name, 'products'), searchText)) {
                        glassDecors.push(product);
                        count++;
                    }

                    return true;
                })

                const menu = SMenu(glassDecors, productImages, 'glass');

                let langKey = 'Glasses';

                if (isMobile) langKey += 'Mobile';

                tabsContent.push({
                    menuItem: parse(Lang(langKey, 'tabs')),
                    render: <SelectDTDTab menu={menu}/>,
                });
            }
        }

        this.setState({tabsContent: tabsContent, count: count});
    }

    /**
     * @param {String} text
     * @private
     */
    _onChangeSearchText = (text) => {
        this.setState({searchText: text});

        this.actualizeTabContents(text);
    }

    render() {
        return <SimpleTabs
            count={this.state.count}
            searchText={this.state.searchText}
            tabsContent={this.state.tabsContent}
            onClickSteps={this.props.onClickSteps}
            onChangeSearchText={this._onChangeSearchText}
        />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectFill)