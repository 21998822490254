import React, {useCallback, useMemo} from 'react';
import {Lang} from "../../../../Utils";
import {Paper, Container, Grid, Typography, Divider, Button} from "@material-ui/core";
import TopMenu from "../../Menu/TopMenu";
import VerticalSidebar from "../../Components/Molecules/VerticalSidebar";
import {makeStyles} from "@material-ui/core/styles";
import MiddleMenu from "../../Menu/MiddleMenu";
import SiteAppBar from "../../Menu/SiteAppBar";
import JSONTree from 'react-json-tree';
import {useStore} from "../../../../store";
import {getWardrobeAttributes} from "../../../../utils/parameters";
import {getType as getRoomType} from "../../../../utils/room";
import {getFormattedPrice} from "../../../../utils/price";
import {Redirect, useHistory} from "react-router-dom";
import {useSteps} from "../../../../Stores/Steps";
import {START_STEP} from "../../../../config";

const useStyles = makeStyles(() => ({
    mainContainer: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    mainGridRight: {
        textAlign: 'right',
    },
    mainGridCenter: {
        textAlign: 'center',
    },
    divider: {
        height: 3,
        margin: 4,
        backgroundColor: "black",
    },
    infoText: {
        paddingLeft: 30,
    },
    priceText: {
        paddingRight: 30,
    },
    nextButtonActive: {
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        }
    },
    buttonsContainer: {
        paddingTop: 10,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 30,
    },
    accessoriesContainer: {
        paddingLeft: 40,
    }
}));

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @param price
 * @returns {JSX.Element}
 * @constructor
 */
const PriceCalculation = ({fullScreenHandle, onClickSteps, price}) => {
    const classes = useStyles();
    const history = useHistory();
    const width = useStore(state => state.width);
    const height = useStore(state => state.height);
    const depth = useStore(state => state.depth);
    const spacesCount = useStore(state => state.spacesCount);
    const selectedRoomType = useStore(state => state.roomType);
    const selectedObjType = useStore(state => state.objType);
    const emailAddress = useStore(state => state.emailAddress);
    const setStep = useSteps(state => state.setStep);
    const openModal = useSteps(state => state.openModal);

    const roomType = useMemo(() => {
        return getRoomType(selectedRoomType, selectedObjType);
    }, [selectedRoomType, selectedObjType]);

    const wardrobeAttributes = getWardrobeAttributes({
        width: width,
        height: height,
        depth: depth,
        type: roomType,
        spacesCount: spacesCount,
    });

    const currencyCode = 'EUR';

    const onClickSelectStep = useCallback(
        () => {
            setStep('order');
            history.push('/' + Lang('order', 'navigation'));
        },
        [setStep, history]
    );

    const handleOpenPhoneSave = useCallback(
        () => {
            openModal('phoneSave');
        },
        [openModal]
    );

    const parametersInfo = useMemo(() => {
        let parametersInfo = wardrobeAttributes.width + 'x' + wardrobeAttributes.height;

        if (selectedObjType !== 'd') {
            parametersInfo += 'x' + wardrobeAttributes.depth;
        }

        return parametersInfo;
    }, [selectedObjType, wardrobeAttributes.width, wardrobeAttributes.height, wardrobeAttributes.depth]);

    if (selectedObjType === '') {
        return <Redirect to={'/' + Lang(START_STEP, 'navigation')}/>
    }

    if (price.prices) {
        return (
            <>
                <TopMenu mainStep="order"/>
                <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                <Paper>
                    <SiteAppBar title={Lang('PriceCalculation')} onClickSteps={onClickSteps}/>
                    <Container className={classes.mainContainer}>
                        <Grid container spacing={1}>
                            <Grid item xs={8} spacing={5}>
                                <Typography variant='h6' className={classes.infoText}>
                                    {Lang('CalculationItemName' + selectedObjType.toUpperCase())} ({parametersInfo})
                                </Typography>
                            </Grid>
                            <Grid item xs={4} spacing={5} className={classes.mainGridRight}>
                                <Typography variant='h6'
                                            className={classes.priceText}>{getFormattedPrice(price.prices.wardrobe['priceWithoutVat'], price.currencyCode)}</Typography>
                            </Grid>
                        </Grid>
                        {Object.values(price.prices.accessories).length > 0 && (
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography variant='h6'
                                                className={classes.infoText}>{Lang('Accessories')}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.mainGridRight}>
                                    <Typography variant='h6'
                                                className={classes.priceText}>{getFormattedPrice(price.prices.accessories['priceWithoutVat'], price.currencyCode)}</Typography>
                                </Grid>
                            </Grid>
                        )}
                        {Object.values(price.prices.accessories).length > 0 && price.prices.accessories.includedItems.map((item) => (
                            <>
                                {item.priceWithoutVat > 0 && (
                                    <Container className={classes.accessoriesContainer}>
                                        <Typography className={classes.infoText}>
                                            {Lang(item.name, 'accessories')}: {getFormattedPrice(item.priceWithoutVat)}
                                        </Typography>
                                    </Container>
                                )}
                            </>
                        ))}
                        {typeof price.prices.packing !== 'undefined' && (
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography variant='h6' className={classes.infoText}>{Lang('Packing')}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.mainGridRight}>
                                    <Typography variant='h6'
                                                className={classes.priceText}>{getFormattedPrice(price.prices.packing['priceWithoutVat'], price.currencyCode)}</Typography>
                                </Grid>
                            </Grid>
                        )}
                        {typeof price.prices.delivery !== 'undefined' && (
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography variant='h6'
                                                className={classes.infoText}>{Lang('Delivery')}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.mainGridRight}>
                                    <Typography variant='h6'
                                                className={classes.priceText}>{getFormattedPrice(price.prices.delivery['priceWithoutVat'], price.currencyCode)}</Typography>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Divider className={classes.divider}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant='h5'
                                            className={classes.infoText}><strong>{Lang('TotalPrice')}</strong></Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.mainGridRight}>
                                {price.totalPriceWithoutVat && <Typography variant='h5'
                                                                           className={classes.priceText}><strong>{getFormattedPrice(price.totalPriceWithoutVat, currencyCode)}</strong></Typography>}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant='h5'
                                            className={classes.infoText}><strong>{Lang('TotalPriceWithVat').replace("{0}", price.vat)}</strong></Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.mainGridRight}>
                                {price.totalPriceWithVat && <Typography variant='h5'
                                                                        className={classes.priceText}><strong>{getFormattedPrice(price.totalPriceWithVat, currencyCode)}</strong></Typography>}
                            </Grid>
                        </Grid>
                        {emailAddress === 'sopko@its4you.sk' && <JSONTree data={price}/>}
                        {emailAddress === 'mericko@its4you.sk' && <JSONTree data={price}/>}
                    </Container>
                    <Container className={classes.buttonsContainer}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    className={classes.nextButtonActive}
                                    onClick={handleOpenPhoneSave}
                                >
                                    <div className={classes.buttonActiveDiv}>
                                        <Typography variant="h6" className={classes.buttonNextText}>
                                            {Lang('SaveOrder', 'buttons')}
                                        </Typography>
                                    </div>
                                </Button>
                            </Grid>
                            <Grid item xs={8} className={classes.mainGridCenter}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.nextButtonActive}
                                    onClick={() => onClickSelectStep()}
                                >
                                    <div className={classes.buttonActiveDiv}>
                                        <Typography variant="h6" className={classes.buttonNextText}>
                                            {Lang('SubmitOrder')}
                                        </Typography>
                                    </div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
                <VerticalSidebar onClickSteps={onClickSteps}/>
            </>
        );
    } else {
        return (<></>)
    }
};

export default PriceCalculation