import React from 'react';
import {connect} from "react-redux";
import {APP_LOAD_ORDER} from "../../../../constants/actionTypes";
import agent from "../../../../agent";
import {apiStore} from "../../../../store";
import {apiSteps} from "../../../../Stores/Steps";
import {CircularProgress, Modal} from "@material-ui/core";
import {Lang} from "../../../../Utils";
import {apiOrder} from "../../../../Stores/Order";
import uuid from "react-uuid";

const mapStateToProps = state => ({
    ...state.todos,
});

const mapDispatchToProps = dispatch => ({
    onLoadOrder: (payload) =>
        dispatch({type: APP_LOAD_ORDER, payload}),
});


class LoadOrder extends React.Component {

    state = {
        loaded: null,
    }

    componentDidMount() {
        this.props.onLoadOrder(agent.Order.load(this.props.objId, this.props.APIUUId));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

       if (prevState.loaded !== this.props.objId && this.props.loadedOrder && this.props.productImages) {
            if (this.props.loadedOrder.data) {
                const loadData = this.props.loadedOrder.data;

                if (typeof loadData !== 'undefined') {
                    apiOrder.getState().setUUId(uuid());
                    apiStore.getState().setRoomHeight(loadData.height);
                    apiStore.getState().setRoomWidth(loadData.width);
                    apiStore.getState().setRoomDepth(loadData.depth);
                    apiStore.getState().saveSpacesCount(loadData.spacesCount);
                    apiStore.getState().setObjType(loadData.objType);
                    apiStore.getState().setRoomType(loadData.roomType);

                    for (let i = 1; i <= loadData.spacesCount; i++) {
                        apiStore.getState().setCorpusType(i, loadData.selectedCorpuses[i]);
                    }

                    apiStore.getState().setDividedSpacesData(loadData.dividedSpaces);
                    apiStore.getState().setDividedSpacesWidthData(loadData.dividedSpacesWidth);

                    if (loadData.selectedProfile) {
                        apiStore.getState().setSelectedProfileData(loadData.selectedProfile);
                    }

                    apiStore.getState().setSelectedProfileIdData(loadData.selectedProfileId);
                    apiStore.getState().setDoorFillsAttributesData(loadData.doorFillsAttributes);

                    apiStore.getState().setSelectedDoorsSpaceData(loadData.doorsSpace);
                    apiStore.getState().setSelectedDoorsSpaceAttributesData(loadData.selectedDoorsSpaceAttributes);

                    if (loadData.mainDTDId && this.props.productImages && this.props.productImages.large) {
                        apiStore.getState().setDTDImage('main', loadData.mainDTDId, this.props.productImages.large[loadData.mainDTDId]);
                    }

                    if (loadData.secondDTDId && this.props.productImages && this.props.productImages.large) {
                        apiStore.getState().setDTDImage('second', loadData.secondDTDId, this.props.productImages.large[loadData.secondDTDId]);
                    }

                    apiStore.getState().setDoorFillsIdsData(loadData.doorFillsIds);

                    Object.values(loadData.doorFillsData).map((data) => {
                        apiStore.getState().setDoorFillForId(data.id, data.type, data.productId, this.props.productImages.large[data.productId]);
                    });

                    apiStore.getState().setSelectedCorpusesData(loadData.selectedCorpuses);
                    apiStore.getState().setParametersData(loadData.parameters);


                    this.setState({loaded: this.props.objId});

                    apiSteps.getState().setStep('setWardrobeParameters');
                    this.props.history.push('/' + Lang('setWardrobeParameters', 'navigation'));
                }
            }
        }
    }
    render() {
        return (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={this.props.classes.modal}
                    open={true}
                    closeAfterTransition
                    disableEnforceFocus
                    disableBackdropClick
                >
                    <>
                        <div>
                            <CircularProgress/>
                        </div>
                    </>
                </Modal>
        );
    }
/*
    render() {
        return (
            <>--{this.props.loadedOrder && this.props.loadedOrder.data.mainDTDId}--
                loadedOrder: {JSON.stringify(this.props.loadedOrder)}

                loadedOrder: {this.props.productImages && JSON.stringify(this.props.productImages.large[this.props.loadedOrder.data.mainDTDId])}
            </>
        );
    }*/
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadOrder)