import React, {useCallback, useState} from "react";
import {Segment, Modal, Image, Button, Header} from "semantic-ui-react";
import {Lang} from "../../Utils";
import {useStore} from "../../store";
import ReactPixel from 'react-facebook-pixel';
import {useSteps} from "../../Stores/Steps";
import {useHistory} from "react-router-dom";
import {useOrder} from "../../Stores/Order";
import uuid from "react-uuid";

let dataLayer = window.dataLayer = window.dataLayer || [];

const SelectTypeSegment = ({typeName, imageName, onClick}) => {
    const [isSelected, setSelected] = useState(false);

    return (
        <Segment
            as="a"
            circular
            color={isSelected ? 'green' : 'grey'}
            onPointerDown={onClick}
            className="selectObjType"
            onPointerOver={() => setSelected(true)}
            onPointerOut={() => setSelected(false)}
        >
            <Image size="medium" src={process.env.PUBLIC_URL + '/images/Types/' + imageName}/>
            <Header color={isSelected ? 'green' : 'black'}>
                {Lang(typeName)}
            </Header>
        </Segment>
    )
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SelectTypeModal = () => {
    const history = useHistory();
    const actualStep = useSteps(state => state.actualStep);
    const [isOpen, setOpen] = useState(actualStep !== 'load');
    const setObjType = useStore(state => state.setObjType);
    const setRoomType = useStore(state => state.setRoomType);
    const setStep = useSteps(state => state.setStep);
    const setUUId = useOrder(state => state.setUUId);

    const onClickModal = useCallback(
        (e, type) => {

            e.stopPropagation(); // stop it at the first intersection
            setOpen(false);
            setObjType(type);

            if (type === 'd') {
                setUUId(uuid());
                setRoomType('a');
                setStep('setWardrobeParameters');
                history.push('/' + Lang('setWardrobeParameters', 'navigation'));
            }

            const data = {
                event: 'objTypeSelected',
                yourData: {
                    type: type
                }
            };

            dataLayer.push(data);

            let event = '';

            switch (type) {
                case 'vd':
                    event = event + ' Komplet'
                    break;
                case 'v':
                    event = event + ' Vnutro'
                    break;
                case 'd':
                    event = event + ' Posuv'
                    break;
                default:
                    event = 'Lead'
            }

            //ReactPixel.trackCustom(event);
        },
        [setOpen, setObjType, setRoomType, setStep, Lang, setUUId]
    );

    return (
        <>
            <Modal open={isOpen}>
                <Modal.Header>{Lang("SelectTypeInfo")}</Modal.Header>
                <Modal.Content>
                    <Segment.Group horizontal>
                        <SelectTypeSegment
                            type="vd"
                            typeName="TypeVDName"
                            imageName="skrina640_470.jpg"
                            onClick={e => onClickModal(e, 'vd')}
                        />
                        <SelectTypeSegment
                            type="d"
                            typeName="TypeDName"
                            imageName="posuv640x470.jpg"
                            onClick={e => onClickModal(e, 'd')}
                        />
                        <SelectTypeSegment
                            type="v"
                            typeName="TypeVName"
                            imageName="vnutro640x470.jpg"
                            onClick={e => onClickModal(e, 'v')}
                        />
                    </Segment.Group>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default SelectTypeModal;