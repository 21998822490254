import React from "react";
import {Icon, Image, Step, Sticky} from "semantic-ui-react";
import {ICONS, STEP_MAIN_TAB} from "../../config";
import {Lang} from "../../Utils";
import {useStore} from "../../store";
import {useSteps} from "../../Stores/Steps";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import {Paper} from "@material-ui/core";
import PriceText from "../../Preview/PriceText";
import {isMobile} from 'react-device-detect';
import BottomMenu from "./Menu/BottomMenu";

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    stepsBackground: {
        backgroundColor: theme.palette.grey[800],
        backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/page-title-background.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    headerPadding: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    priceBox: {
        padding: 15,
    },
    priceText: {
        fontWeight: 'bold',
        fontSize: 18,
    },
}));

const Header = ({contextRef, onClickSteps}) => {
    const classes = useStyles();
    const actualStep = useSteps(state => state.actualStep);
    const objType = useStore(state => state.objType);
    const mainSize = useStore(state => state.mainSize);
    const isDev = (typeof window.isSiteDev !== "undefined" && window.isSiteDev);

    let showBig = (mainSize !== 'mini');
    const stepSize = mainSize === 'big' ? 'tiny' : mainSize;
    const Steps = [];

    Steps.push({name: 'roomType', label: 'Type'});
    Steps.push({name: 'setWardrobeParameters', label: 'Attributes'});

    if ('d' !== objType) {
        Steps.push({name: 'selectCorpuses', label: 'Corpuses'});
    }

    if ('v' !== objType) {
        Steps.push({name: 'selectDoors', label: 'Doors', go: 'selectDoorSpacesCount'});
    }

    Steps.push({name: 'order', label: 'Order'});

    const showPrice = (actualStep !== 'load' && actualStep !== '');

    if (isMobile) {
        return (
            <>
                <BottomMenu onClickSteps={onClickSteps}/>
                <Container maxWidth="lg" style={{textAlign: 'right', alignItems: 'right'}}>
                    <Typography
                        align="right"
                        className={classes.priceText}
                    >
                        {isDev && ('DEV')}&nbsp;{showPrice && <PriceText/>}
                    </Typography>
                </Container>
            </>
        );
    } else {
        return (
            <>
                <Container maxWidth="lg">
                    <Grid container className={classes.headerPadding}>
                        <Grid item md={3}>
                            <Image size="medium" src={process.env.PUBLIC_URL + '/images/logo-orange_405x60.png'}/>
                        </Grid>
                        <Grid item md={6} className={classes.headerTitle}>
                            <Typography
                                component="h2"
                                variant="h5"
                                color="inherit"
                                align="center"
                                noWrap
                            >
                                {Lang('SiteTitle')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                        </Grid>
                    </Grid>
                </Container>
                <Box className={classes.stepsBackground}>
                    <Container maxWidth="lg">
                        <Paper>
                            <Grid container direction="row-reverse">
                                <Grid md={3}>
                                    <Sticky context={contextRef}>
                                        <Paper>
                                            <Box className={classes.priceBox}>
                                                <Typography
                                                    align="right"
                                                    className={classes.priceText}
                                                >
                                                    {isDev && ('DEV')}&nbsp;{showPrice && <PriceText/>}
                                                </Typography>
                                            </Box>
                                        </Paper>
                                    </Sticky>
                                </Grid>
                                <Grid md={9}>
                                    <Step.Group unstackable widths={5} size={stepSize} className="topStepGroupContent">
                                        {Steps.map(stepData => (
                                            <Step
                                                key={stepData.name + 'Step'}
                                                active={STEP_MAIN_TAB[actualStep] === stepData.name}
                                                stackable='tablet'
                                            >
                                                {showBig && <Icon name={ICONS[stepData.name]} size={stepSize}/>}
                                                <Step.Content>
                                                    {!showBig && <Icon name={ICONS[stepData.name]} size="big"/>}
                                                    <Step.Title title={Lang(stepData.label + 'Title')}>
                                                        <Typography
                                                            color={STEP_MAIN_TAB[actualStep] === stepData.name ? 'primary' : ''}
                                                            variant='subtitle1'>{Lang(stepData.label)}</Typography>
                                                    </Step.Title>
                                                </Step.Content>
                                            </Step>))}
                                    </Step.Group>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                </Box>
            </>
        );
    }
};

export default Header;