import {
    ASYNC_START,
    ASYNC_END,
} from './constants/actionTypes';

const promiseMiddleware = store => next => action => {
    if (isPromise(action.payload)) {
        store.dispatch({type: ASYNC_START, subtype: action.type, actions: action});

        action.payload.then(
            result => {
                if (result.status) {
                    if (result.status === 'false') {
                        action.payload = result.errors;
                        action.error = true;
                    } else {
                        action.payload = result.result;
                        action.error = false;
                    }
                } else {
                    action.payload = result;
                    action.error = false;
                }

                store.dispatch({type: ASYNC_END, promise: action.payload});
                store.dispatch(action);

                if (action.error && action.redirectFail) {
                    action.redirectFail.call(result.errors);
                } else if (action.redirect) {
                    action.redirect.call();
                }
            },
            error => {
                action.error = true;
                action.payload = error.response ? error.response.body: null;

                store.dispatch({type: ASYNC_END, promise: action.payload});
                store.dispatch(action);
            }
        );

        return;
    }

    next(action);
};

const localStorageMiddleware = store => next => action => {
    next(action);
};

function isPromise(v) {
    return v && typeof v.then === 'function';
}

export {promiseMiddleware, localStorageMiddleware}