import React, {useCallback} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import {AppBar, Button, Grid, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {Lang} from "../../../Utils";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {useHistory} from "react-router-dom";
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    previewButtonActive: {
        textTransform: 'none',
    },
    nextButtonActive: {
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        }
    },
    buttonActiveDiv: {
        lineHeight: 0,
    },
    buttonActiveText: {
        paddingBottom: 0,
        marginBottom: 0,
    },
    buttonActiveCaption: {
        margin: 0,
        padding: 0,
        fontSize: 12,
    },
    mainGridCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxSubtitle: {
        paddingTop: 5,
    },
    boxMobileSubtitle: {
        paddingTop: 5,
    },
    mainGridRight: {
        textAlign: 'right',
    },
    toolbar: {
        margin: 0,
        padding: 5,
    },
    toolbarMobil: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

}));

const SiteAppBar = ({children, title, subtitle, onClickSteps}) => {
    const classes = useStyles();
    const history = useHistory();

    let isPreviewDisabled = ('function' !== typeof onClickSteps.preview);
    let isNextDisabled = ('function' !== typeof onClickSteps.next);

    const onClickSelectStep = useCallback(
        (type) => {
            if (type === 'previewStep' && !isPreviewDisabled) {
                onClickSteps.preview(history);
            }

            if (type === 'nextStep' && !isNextDisabled) {
                onClickSteps.next(history);
            }
        },
        [onClickSteps, history, isPreviewDisabled, isNextDisabled]
    );

    if (isMobile) {
        return (
            <AppBar position="static" color="default">
                <Toolbar className={classes.toolbarMobil}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h5" align="center">
                                {title}
                            </Typography>
                        </Grid>
                        {subtitle && (
                            <Grid item className={classes.boxMobileSubtitle}>
                                <Typography align="center">
                                    {subtitle}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
                {children}
            </AppBar>
        );
    } else {
        return (
            <AppBar position="static" color="default">
                <Toolbar className={classes.toolbar}>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            {onClickSteps.previewStep && (
                                <Button
                                    variant="outlined"
                                    disabled={isPreviewDisabled}
                                    className={classes.previewButtonActive}
                                    onClick={() => onClickSelectStep('previewStep')}
                                    startIcon={<NavigateBeforeIcon/>}
                                >
                                    <div className={classes.buttonActiveDiv}>
                                        <Typography variant="h6" className={classes.buttonActiveText}>
                                            {Lang("PreviewStep")}
                                        </Typography>
                                        <Typography className={classes.buttonActiveCaption}>
                                            {Lang(onClickSteps.previewStep, 'buttons')}
                                        </Typography>
                                    </div>
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={6} className={classes.mainGridCenter}>
                            <Grid container
                                  direction="column"
                                  justify="center"
                                  alignItems="center"
                            >
                                <Grid item>
                                    <Typography variant="h2" align="center">
                                        {title}
                                    </Typography>
                                </Grid>
                                {subtitle && (
                                    <Grid item className={classes.boxSubtitle}>
                                        <Typography align="center">
                                            {subtitle}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs className={classes.mainGridRight}>
                            {onClickSteps.nextStep && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.nextButtonActive}
                                    disabled={isNextDisabled}
                                    onClick={() => onClickSelectStep('nextStep')}
                                    endIcon={<NavigateNextIcon/>}
                                >
                                    <div className={classes.buttonActiveDiv}>
                                        <Typography variant="h6" className={classes.buttonActiveText}>
                                            {Lang("NextStep")}
                                        </Typography>
                                        <Typography className={classes.buttonActiveCaption}>
                                            {Lang(onClickSteps.nextStep, 'buttons')}
                                        </Typography>
                                    </div>
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
                {children}
            </AppBar>
        );
    }
};

export default SiteAppBar;