import React, {useCallback, useState} from "react";
import {useStore} from "../../../store";
import {useSteps} from "../../../Stores/Steps";
import {getParametersHeightInputs} from "../../../utils/parameters";
import {makeStyles, Modal, Box, Grid, Button} from "@material-ui/core";
import DefInput from "../Components/Atoms/DefInput";
import {
    controlParametersInputs,
    getControlData,
    controlHeightInputs,
    getMaxDifferenceHeightValue
} from "../../../utils/control";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography";
import {Lang} from "../../../Utils";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Alert from '@material-ui/lab/Alert';
import ErrorDialog from "../Components/Molecules/ErrorDialog";
import {useHistory} from "react-router-dom";
import {apiOrder} from "../../../Stores/Order";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    dataLayer: {
        event: 'formSend',
        type: 'kalkulace'
    },
}

const useStyles = makeStyles((theme) => ({
    mainBox: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    infoBox: {
        width: 330,
        paddingTop: 10,
        paddingBottom: 10,
    },
    errorBox: {
        paddingTop: 10,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    mainGridCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainGridRight: {
        textAlign: 'right',
    },
    buttonActiveText: {
        paddingBottom: 0,
        marginBottom: 0,
    },
    buttonActiveCaption: {
        margin: 0,
        padding: 0,
        fontSize: 12,
    },
    previewButtonActive: {
        textTransform: 'none',
    },
    nextButtonActive: {
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        }
    },
}));

/**
 * @returns {*}
 * @constructor
 */
const SetWardrobeHeights = () => {
    const classes = useStyles();
    const history = useHistory();
    const [errorTexts, setErrorText] = useState({});
    const parameters = JSON.parse(useStore(state => state.parameters));
    const selectedRoomType = useStore(state => state.roomType);
    const objType = useStore(state => state.objType);
    const isAttributesModalOpen = useSteps(state => state.isModalOpen['attributes']);
    const closeModal = useSteps(state => state.closeModal);
    const heightInputs = getParametersHeightInputs(selectedRoomType);
    const setInputFocus = useStore(state => state.setInputFocus);
    const setParameters = useStore(state => state.setParameters);
    const controlData = getControlData(parameters['Height'], objType);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const setStep = useSteps(state => state.setStep);
    const maxDifferenceHeightValue = getMaxDifferenceHeightValue();

    const handleClose = () => {
        closeModal('attributes');
    };

    const onChangeSetRoomHeight = useCallback(
        (height, inputType) => {
            setParameters(inputType, height);
        },
        [setParameters]
    );

    const onFocusInputType = useCallback(
        (inputType) => {
            setInputFocus(inputType);
        },
        [setInputFocus]
    );

    const controlHeightInputsArray = [];

    {
        heightInputs.map((height) => (
            controlHeightInputsArray.push(height.type)
        ))
    }

    const controlInputs = useCallback(() => {
            const errorTexts = controlParametersInputs(objType, parameters, heightInputs, controlData, true, controlHeightInputsArray);
            const isHeightsDifferent = controlHeightInputs(controlHeightInputsArray, parameters, maxDifferenceHeightValue);

            if (isHeightsDifferent) {
                errorTexts['heights'] = Lang('BadHeightValuesInfo').replace("{0}", maxDifferenceHeightValue)
            }

            setErrorText(errorTexts);
        }, [parameters, heightInputs, controlData, setErrorText, controlHeightInputsArray, maxDifferenceHeightValue]
    );

    const onBlurSetRoomHeight = useCallback(
        (height, inputType) => {
            controlInputs();
        },
        [controlInputs]
    );

    const onClickSelectStep = useCallback(
        (history) => {
            const errorTexts = controlParametersInputs(objType, parameters, heightInputs, controlData, true, controlHeightInputsArray);

            const isHeightsDifferent = controlHeightInputs(controlHeightInputsArray, parameters, maxDifferenceHeightValue);

            if (isHeightsDifferent) {
                errorTexts['heights'] = Lang('BadHeightValuesInfo').replace("{0}", maxDifferenceHeightValue)
            }

            if (Object.keys(errorTexts).length) {
                setErrorText(errorTexts);
                setOpenErrorDialog(true);
            } else {
                apiOrder.getState().setInsert(true);
                TagManager.dataLayer(tagManagerArgs);

                setStep('priceCalculation');
                history.push('/' + Lang('priceCalculation', 'navigation'));
            }
        },
        [parameters, heightInputs, controlData, setStep, setOpenErrorDialog, setErrorText, controlHeightInputsArray]
    );

    const openDialogErrors = [];

    if (errorTexts) {
        heightInputs.map((inputData) => {
            if (errorTexts[inputData.type]) {
                const inputLabel = Lang(inputData.type);
                let label = '';

                if (errorTexts[inputData.type].type === 'IsEmpty') {
                    label = Lang('InputCanNotBeEmpty', 'inputs').replace("{0}", inputLabel);
                } else if (errorTexts[inputData.type].type === 'MinWardrobeError') {
                    label = Lang(errorTexts[inputData.type].type, 'inputs').replace("{0}", inputLabel).replace("{1}", controlData['Height']['min']);
                } else if (errorTexts[inputData.type].type === 'MaxWardrobeError') {
                    label = Lang(errorTexts[inputData.type].type, 'inputs').replace("{0}", inputLabel).replace("{1}", controlData['Height']['max']);
                } else if (errorTexts[inputData.type].label) {
                    label = errorTexts[inputData.type].label;
                }

                openDialogErrors.push(label);
            }

            return true;
        })

        if (errorTexts['heights']) {
            openDialogErrors.push(errorTexts['heights']);
        }
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={isAttributesModalOpen}
                onClose={handleClose}
                closeAfterTransition
                disableEnforceFocus
                disableBackdropClick
            >
                <div className={classes.paper}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Box className={classes.infoBox}>
                            <Alert severity="info">{Lang('controlAndSetHeights', 'info')}</Alert>
                            {errorTexts['heights'] && (
                                <Box className={classes.errorBox}>
                                    <Alert severity="error">{errorTexts['heights']}</Alert>
                                </Box>
                            )}
                        </Box>
                        {heightInputs.map((height) => (
                            <Box className={classes.mainBox}>
                                <DefInput
                                    type={height.type}
                                    defaultValue={parameters[height.type] ?? parameters['Height']}
                                    handleChange={onChangeSetRoomHeight}
                                    onFocusInputType={onFocusInputType}
                                    onBlurInputType={onBlurSetRoomHeight}
                                    errorObject={errorTexts[height.type] ?? ''}
                                />
                            </Box>
                        ))}
                    </form>

                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Button
                                variant="outlined"
                                className={classes.previewButtonActive}
                                onClick={handleClose}
                                startIcon={<NavigateBeforeIcon/>}
                            >
                                <div className={classes.buttonActiveDiv}>
                                    <Typography className={classes.buttonActiveCaption}>
                                        {Lang("PreviewStep")}
                                    </Typography>
                                </div>
                            </Button>
                        </Grid>
                        <Grid item xs className={classes.mainGridRight}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onClickSelectStep(history)}
                                endIcon={<NavigateNextIcon/>}
                                className={classes.nextButtonActive}
                            >
                                <Typography variant="h6" className={classes.buttonActiveText}>
                                    {Lang("NextStep")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            <ErrorDialog
                openDialogErrors={openDialogErrors}
                openErrorDialog={openErrorDialog}
                setOpenErrorDialog={setOpenErrorDialog}
            />
        </>
    );
};

export default SetWardrobeHeights;
