import React, {useCallback} from "react";
import {Icon, Menu} from "semantic-ui-react";
import {Lang} from "../../../Utils";
import {useHistory} from 'react-router-dom';
import ShowInfo from "../Modals/ShowInfo";

const BottomMenu = ({onClickSteps}) => {
    const history = useHistory();
    const isPreviewDisabled = ('function' !== typeof onClickSteps.preview);
    const isNextDisabled = ('function' !== typeof onClickSteps.next);

    const onClickSelectStep = useCallback(
        (type) => {
            if (type === 'previewStep' && !isPreviewDisabled) {
                onClickSteps.preview(history);
            }

            if (type === 'nextStep' && !isNextDisabled) {
                onClickSteps.next(history);
            }
        },
        [onClickSteps, history, isPreviewDisabled, isNextDisabled]
    );

    const iconLeft = 'angle left';
    const iconRight = 'angle right';

    return (<Menu widths={3} fluid inverted>
        {onClickSteps.previewStep && (
            <Menu.Item
                as="a"
                id={'previewStep'}
                name={'previewStep'}
                disabled={isPreviewDisabled}
                onClick={() => onClickSelectStep('previewStep')}
            >
                {iconLeft && <Icon name={iconLeft}/>}
                {Lang("Preview")}
            </Menu.Item>
        )}
        {!onClickSteps.previewStep && (
            <Menu.Item
                as="a"
            >
            </Menu.Item>
        )}
        <ShowInfo/>
        {onClickSteps.nextStep && (
            <Menu.Item
                as="a"
                id={'nextStep'}
                name={'nextStep'}
                disabled={isNextDisabled}
                onClick={() => onClickSelectStep('nextStep')}
            >
                {Lang("Next")}&nbsp;
                <Icon name={iconRight}/>
            </Menu.Item>
        )}
    </Menu>);
};

export default BottomMenu;
