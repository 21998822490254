import {Lang} from "../Utils";

/**
 * @param actualHeight
 * @param objType
 * @returns {{Height: {min: number, max: number}, Width: {min: (number), max: number}, Depth: {min: number, max: number}}}
 */
export function getControlData(actualHeight = null, objType = '') {
    let minHeight = 230;
    let maxHeight = 279;

    if (actualHeight) {
        if (actualHeight <= 279 && actualHeight >= 259.5) {
            minHeight = 259.5;
        } else if (actualHeight < 259.5 && actualHeight >= 239.5) {
            minHeight = 239.5;
            maxHeight = 259.49;
        } else if (actualHeight < 239.5 && actualHeight >= 230) {
            maxHeight = 239.49;
        }
    }

    return {
        'Width': {
            'min': objType === 'v' ? 45 : 90,
            'max': 700,
        },
        'Depth': {
            'min': 35,
            'max': 90,
        },
        'Height': {
            'min': minHeight,
            'max': maxHeight,
        }
    }
}

/**
 *
 * @param {String} type
 * @param {Number} value
 * @param {Number} min
 * @param {Number} max
 * @param {Boolean} controlEmpty
 * @returns {Object}
 */
export function controlParametersInput(type, value, min, max, controlEmpty) {
    let newErrorText = '';
    let langKey = '';
    let errorType = '';
    const mainType = type.indexOf('Height') > 0 ? 'Height' : type;

    if (value) {

        let controlValue = '';

        if (!Number(value)) {
            langKey = 'InvalidNumberError';
        } else {
            if (min > value) {
                errorType = 'MinWardrobeError';
                langKey = 'MinWardrobe' + mainType + 'Error';
                controlValue = min;
            } else if (max < value) {
                errorType = 'MaxWardrobeError';
                langKey = 'MaxWardrobe' + mainType + 'Error';
                controlValue = max;
            }
        }

        if (langKey !== '') {
            newErrorText = Lang(langKey, 'inputs').replace("{0}", controlValue);
        }
    } else if (controlEmpty) {
        langKey = type + 'IsEmpty';
        errorType = 'IsEmpty';
    }

    if (errorType) {
        return {label: newErrorText, key: langKey, type: errorType};
    } else {
        return false;
    }
}

/**
 * @param {String} objType
 * @param {Object} parameters
 * @param {Object} heightInputs
 * @param {Object} controlData
 * @param {Boolean} controlEmpty
 * @param {Object} controlInputs
 * @returns {{}}
 */
export function controlParametersInputs(objType, parameters, heightInputs, controlData, controlEmpty = false, controlInputs = []) {

    if (Object.keys(controlInputs).length === 0) {
        if (objType === 'd') {
            controlInputs = ['Width', 'Height'];
        } else {
            controlInputs = ['Width', 'Depth', 'Height'];
        }
    }

    const errorTexts = {};

    controlInputs.map(inputType => {
        const controlInputType = inputType.indexOf('Height') > 0 ? 'Height' : inputType;
        const errorText = controlParametersInput(inputType, parameters[inputType], controlData[controlInputType]['min'], controlData[controlInputType]['max'], controlEmpty);

        if (errorText) {
            errorTexts[inputType] = errorText;
        }

        return true;
    })

    return errorTexts;
}

/**
 * @param {Object} heightInputs
 * @param {Object} roomHeightsData
 * @param {Number} maxDifferenceHeightValue
 * @returns {Boolean}
 */
export function controlHeightInputs(heightInputs, roomHeightsData, maxDifferenceHeightValue) {
    let heights = [];

    heightInputs.map((inputName) => {
        if (roomHeightsData[inputName]) {
            heights.push(roomHeightsData[inputName])
        }
    })

    if (heightInputs.length === heights.length) {
        const minNumCount = Math.min(...heights);
        const maxNumCount = Math.max(...heights);

        const dif = maxNumCount - minNumCount;

        return dif > maxDifferenceHeightValue;
    }

    return false;
}

/**
 * @param {String} text
 * @param {String} searchText
 * @returns {boolean}
 */
export function controlSearchText(text, searchText) {
    let success = true;

    if (searchText) {
        success = text.toLowerCase().search(searchText.toLowerCase()) !== -1;
    }

    return success;
}

/**
 * @returns {number}
 */
export function getMaxDifferenceHeightValue() {
    return 1.5;
}

/**
 * @param {string} email
 * @returns {boolean}
 */
export function isEmailAddressValid(email) {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    return (pattern.test(email));
}